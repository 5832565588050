import { FC } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { LayoutRoutes } from "src/layout/routes";
import { LayoutError } from "src/layout/error";
import { LayoutHeader } from "src/layout/header";

export const LayoutApp: FC = () => {
  return (
    <div className="layout">
      <LayoutHeader />
      <main className="layout-main">
        <ErrorBoundary
          fallbackRender={({ error }): JSX.Element => (
            <LayoutError error={error} />
          )}
        >
          <LayoutRoutes />
        </ErrorBoundary>
      </main>
      <footer className="layout-footer flex-center">
        <h6>
          <a href="/about">about</a>
        </h6>
      </footer>
    </div>
  );
};
