import { FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { LayoutMenu } from "src/layout/menu";
import { LayoutSettings } from "src/layout/settings";

function isTarget(target, names) {
  return Boolean(
    names
      .map((name) => {
        return target.matches(name) || target.closest(name);
      })
      .filter(Boolean).length
  );
}

export const LayoutHeader: FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const onClickMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const onClickSettingsToggle = () => {
    setSettingsOpen(!settingsOpen);
  };

  const initEffect = () => {
    const onClick = (event) => {
      if (
        menuOpen &&
        !isTarget(event.target, [".layout-menu", ".menu-toggle-button"])
      ) {
        setMenuOpen(false);
      }
      if (
        settingsOpen &&
        !isTarget(event.target, [".layout-settings", ".settings-toggle-button"])
      ) {
        setSettingsOpen(false);
      }
    };
    document.addEventListener("click", onClick);
    return () => document.removeEventListener("click", onClick);
  };
  useEffect(initEffect, [menuOpen, settingsOpen]);

  return (
    <>
      <header className="layout-header flex">
        <div className="left">
          <button
            className="button menu-toggle-button"
            onClick={onClickMenuToggle}
          >
            <i className="fa-solid fa-bars"></i>
            <span className="sr-only">Menu</span>
          </button>
        </div>

        <h1>
          <Link to="/">gnosticode</Link>
        </h1>

        <div className="right">
          <button
            className="button settings-toggle-button"
            onClick={onClickSettingsToggle}
          >
            <i className="fa-solid fa-gear"></i>
            <span className="sr-only">Settings</span>
          </button>
        </div>
      </header>

      <LayoutMenu
        {...{
          open: menuOpen,
          setOpen: setMenuOpen,
        }}
      />

      <LayoutSettings
        {...{
          open: settingsOpen,
          setOpen: setSettingsOpen,
        }}
      />
    </>
  );
};
