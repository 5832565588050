import { FC } from "react";

interface Props {
  open?: boolean;
  setOpen?;
}

export const LayoutSettings: FC<Props> = (props: Props) => {
  const { open } = props;

  return (
    <div
      className={`layout-settings layout-slide-in from-right ${
        open ? "show" : "hide"
      }`}
    >
      settings
    </div>
  );
};
