import { FC } from "react";
import { Link } from "react-router-dom";

// Route Error shows when the /route-path is invalid
const RouteError: FC = () => {
  return (
    <div>
      <h2>Error: Route Not Found</h2>
      <Link to="/" className="button">
        Back to Home
      </Link>
    </div>
  );
};

export default RouteError;
