import { FC } from "react";

const RouteHome: FC = () => {
  return (
    <div className="route-home padding-2">
      <img
        className="avatar"
        src="/wizard.png"
        alt="Wizard Avatar Designed by Freepik freepik.com"
        title="Wizard Avatar Designed by Freepik freepik.com"
      />
      <p>
        {" "}
        Welcome Friend,
        <br />
        I hope you find something useful here. <br />
        Software Development examples of all kinds will be found here.
      </p>

      <p>Topics:</p>

      <ul>
        <a href="/svg">Drawing SVG Elements</a>
      </ul>
    </div>
  );
};

export default RouteHome;
