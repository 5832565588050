import { FC } from "react";
import { Link } from "react-router-dom";

interface Props {
  open?: boolean;
  setOpen?;
}

export const LayoutMenu: FC<Props> = (props: Props) => {
  const { open, setOpen } = props;

  const onClickMenuItem = () => {
    setOpen(false);
  };

  return (
    <nav
      className={`layout-menu layout-slide-in from-left ${
        open ? "show" : "hide"
      }`}
    >
      <Link to="/" onClick={onClickMenuItem}>
        Home
      </Link>

      <Link to="/about" onClick={onClickMenuItem}>
        About
      </Link>
      <div className="topics-heading">Topics: </div>
      <Link to="/svg" onClick={onClickMenuItem}>
        Drawing SVG Elements
      </Link>
    </nav>
  );
};
