import { FC } from "react";
import { Routes, Route } from "react-router-dom";
import loadable from "@loadable/component";
import RouteHome from "src/routes/home";
import RouteAbout from "src/routes/about";
import RouteError from "src/routes/error";

const RouteSvg = loadable(() => import("src/routes/svg"));
const RouteSvgGrid = loadable(() => import("src/routes/svg-grid"));

export const LayoutRoutes: FC = () => {
  return (
    <>
      <Routes>
        <Route path="*" element={<RouteError />} />
        <Route path="/" element={<RouteHome />} />
        <Route path="/about" element={<RouteAbout />} />
        <Route path="/svg" element={<RouteSvg />} />
        <Route path="/svg-grid" element={<RouteSvgGrid />} />
      </Routes>
    </>
  );
};
