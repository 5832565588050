import { FC } from "react";

interface Props {
  error: Error;
}

// Layout error shows if anything causes errors below Error Boundary
export const LayoutError: FC<Props> = (props: Props) => {
  const { error } = props;
  const { message } = error;
  const time = new Date().toString();
  return (
    <div className="layout-error padding-2">
      <h2>Application Error:</h2>
      <pre>time: {time}</pre>
      <pre>error: {message}</pre>
    </div>
  );
};
