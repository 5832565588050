import { FC } from "react";

const RouteAbout: FC = () => {
  return (
    <div className="padding-2">
      <h2>About Gnosticode</h2>
      <p>
        Hello, I'm a Software Developer interested in collaboration in Open
        Source. This is my place to share my efforts in learning. I hope you
        find something useful. Please feel free to contact me at
        gnosticode@gmail.com
      </p>

      <h3>Credits: Site Styles</h3>

      <ul>
        <li>
          Wizard Avatar Designed by Freepik{" "}
          <a href="https://www.freepik.com/free-vector/medieval-magician-set_11235740.htm">
            freepik.com
          </a>
        </li>
        <li>
          Title font is{" "}
          <a href="https://fonts.google.com/specimen/Zen+Dots/about?query=zen+dots">
            Zen Dots from Google
          </a>
        </li>
        <li>
          <a href="https://fontawesome.com/">Font Awesome for icons</a>
        </li>
        <li>
          <a href="https://necolas.github.io/normalize.css/">
            Normalize.css for reset
          </a>
        </li>
        <li>
          <a href="https://milligram.io/">Milligram CSS for framework</a>
        </li>
      </ul>

      <h3>Credits: Site Functions</h3>

      <li>
        <a href="https://vitejs.dev/">Vite for build tools</a>
      </li>
      <li>
        <a href="https://react.dev/">React for runtime</a>
      </li>
      <li>
        <a href="https://sass-lang.com/">Sass for SCSS build</a>
      </li>
    </div>
  );
};

export default RouteAbout;
